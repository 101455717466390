<template>

  <b-card-code
    title="Edit Payment"
  >
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <ValidationObserver v-slot="{ handleSubmit}">
  <b-form @submit.prevent="handleSubmit(storePayment)"> 
    <b-alert
      variant="danger"
      show
      dismissible
      v-if="validationErrors && validationErrors.message"
    >
      <div class="alert-body">
        <span><strong>{{validationErrors.message}}</strong></span>
      </div>
    </b-alert>
      <b-row class="mt-2">
        <b-col md="6">
            <b-form-group
                label-for="mc-email"
                label="Student"
            >
                <ValidationProvider rules="required" name="Student" v-slot="{ errors }">
                <v-select
                    v-model="payment.student_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="fullName"
                    :options="students"
                    :reduce="student => student.id"
                    placeholder = 'Select Student'
                />
                <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
            </b-form-group>
        </b-col>
        
        
        <b-col md="6">
            <b-form-group
                label-for="mc-email"
                label="Curso"
            >
                <ValidationProvider rules="required" name="Curso" v-slot="{ errors }">
                    <v-select
                        v-model="payment.course"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="parentCourses"
                        :reduce="course => course"
                        placeholder = 'Select Course'
                        @input="setSelected"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
            </b-form-group>
        </b-col>
        <b-col md="6" v-if="subCoursesData.length > 0">
            <b-form-group
                label-for="mc-email"
                label="Sub Curso"
            >
                <ValidationProvider rules="required" name="Sub Curso" v-slot="{ errors }">
                    <v-select
                        v-model="payment.subCourse"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="subCoursesData"
                        :reduce="subCourse => subCourse"
                        placeholder = 'Select Sub Curso'
                        @input="setSubCourse"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
            </b-form-group>
        </b-col>
        
        <b-col md="6">
            <b-form-group
                label-for="payment-type"
                label="Payment Type"
            >
            <ValidationProvider rules="required" name="Payment Type" v-slot="{ errors }">
                <v-select
                    v-model="payment.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="paymentTypes"
                    :reduce="payment => payment.name"
                    placeholder = 'Select Payment Type'
                />
                <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Issue Date"
            label-for="mc-company"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="Issue Date" v-slot="{ errors }">
              <b-form-datepicker
                id="issue-date"
                v-model="payment.issue_date"
                class="mb-1"
                placeholder="Issue Date"
                locale='es'
                show-decade-nav
                start-weekday='1'
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
              
            </div>
          </b-form-group>
        </b-col>
        
        <b-col md="6">
          <b-form-group
            label-for="total-payment"
            label="Total Payment"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="Total Payment" v-slot="{ errors }">
              <b-form-input
                v-model="payment.total_amount"
                id="mc-email"
                placeholder="Total Payment"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        
        <b-col md="6">
          <b-form-group
            label-for="paid-amount"
            label="Paid Amount"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="Paid Amount" v-slot="{ errors }">
              <b-form-input
                v-model="payment.paid_amount"
                id="mc-email"
                placeholder="Paid Amount"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        
      </b-row>
        <b-row>
            <b-col>
        
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1" 
            >
                Update Payment
            </b-button>
         
            </b-col>
        </b-row>
    </b-form>
  </ValidationObserver> 
  </b-overlay>
   </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BFormDatepicker, BCol, BAlert, BAvatar,BCardText, BOverlay, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormFile,BMedia, BMediaAside, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import CourseService from '@/services/course.service.js';
import StudentService from '@/services/student.service.js';
import PaymentService from '@/services/payment.service.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BAvatar,
    BCardText,
    BFormDatepicker,
    BOverlay,
    BAlert
  },
  directives: {
    Ripple,
  },
  computed: {
    validationErrors(){
      return this.errors.data
    },
    subCoursesData() {
      return this.subCourses
    }
  },
  data() {
    return {
      payment: {
        course_id: '',
        student_id: '',
        sub_course_id: '',
        status: '',
        total_amount: 0,
        paid_amount: 0,
        course_name: '',
        issue_date: moment().toDate(),
        course: '',
        subCourse: ''
      },
      paymentTypes: [{ title: 'Full Payment' , name: 'full'}, { title: 'Partial Payment', name: 'partial'}],
      getUser: {},
      students: [],
      courses: [],
      parentCourses: [],
      subCourses: [],
      logoImg: require('@/assets/images/pages/logo-1.png'),
      avatar: '@/assets/images/portrait/small/avatar-s-11.jpg',
      showOverlay: false,
      errors: '',
      id: this.$route.params.id
    }
  },
  watch:{
    '$route'(to) {
      this.id = to.params.id;
    } 
  },
  methods: {
    setSelected(value)
    {
        this.payment.course_id = value.id
        this.payment.course_name = value.name 
        this.subCourses = this.courses.filter(course => {
            if(course.parent_id == value.id) {
                return course;
            }
        })
    },
    setSubCourse(value)
    {
        this.payment.sub_course_id = value.id
        this.payment.course_name = value.name 
    },
    storePayment() {
        this.showOverlay = true;
        PaymentService.update(this.payment, this.id).then(response => {
            this.showOverlay = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Payment Added Successfully',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.$router.push('/payment-listing');
            }).catch(error => {
              this.showOverlay = false;
              this.errors = error.response
        });  
    },
    find()
    {
        this.showOverlay = true;
        PaymentService.find(this.id).then(response => {
            this.payment = response.data.data.payment
            if(response.data.data.payment.course) {
                if(response.data.data.payment.course.parent_id !== null) {
                    this.setSelected(response.data.data.payment.course.parent_id)
                    this.payment.subCourse = response.data.data.payment.course
                    this.payment.course = this.parentCourses.find(x => x.id === response.data.data.payment.course.parent_id);
                    this.payment.course_id = response.data.data.payment.course.parent_id
                    this.payment.sub_course_id = response.data.data.payment.course.id
                } else {
                    this.payment.course = response.data.data.payment.course
                    this.payment.course_id = response.data.data.payment.course.id
                }
            }
            this.showOverlay = false;
        }).catch(error => {
            this.showOverlay = false;
            this.errors = error.response
        });
    },
    getCourses()
    {
      CourseService.getAll().then(response => {
        this.courses = response.data.data.courses;
        this.parentCourses = this.courses.filter(course => {
            if(course.parent_id == null) {
                return course;
            }
        })
        }).catch(error => {
            console.log(error)
        });
    },
    getStudents()
    {
      StudentService.getAll().then(response => {
        this.students = response.data.data.students;
        }).catch(error => {
            console.log(error)
        });
    },
  },
  created() {
      this.getCourses();
      this.getStudents();
      this.find();
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
